.containerCM{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
 /*    border : 1px solid black; */
 padding-top: 50px;
}

.col_raison {
    align-self: center;
    font-size: 30px;
    margin-bottom: 30px;
}

.height{
    height: 40px !important ;
}

.margin{
    padding-bottom: 20px;
}
