.Login {
	background-image: url("../../assets/login.jpg");
	width: 100%;
	height: 100% !important;
	background-repeat: no-repeat;
	background-size: cover;
	z-index:-12;
	position: fixed;
	padding: 0;
}
.form-control {
  height: 0px;
  box-shadow: none;
  color: #969fa4;
}
.form-control:focus {
  border-color: #5cb85c;
}

.textC{
  color: rgba(255, 255, 255, 0.681);
}
.textL{
  color: rgb(255, 255, 255);
}
.form-control,
.btn {
  border-radius: 3px;
}
.signup-form {
  width: 450px;
  margin: 10% 10%;
  /* padding: 0px 0; */
 
  font-size: 17px;
  z-index:1222;
  position: fixed;
  
}
.signup-form h2 {
  color: #636363;
  margin: 10px 0 15px;
  position: relative;
  text-align: center;
 
}
.signup-form h2:before,
.signup-form h2:after {
  content: "";
  height: 2px;
  width: 30%;
  background: #d4d4d4;
  position: absolute;
  top: 50%;
  z-index: 2;
}
.signup-form h2:before {
  left: 0;
}
.signup-form h2:after {
  right: 0;
}
.signup-form .hint-text {
  color: #999;
  margin-bottom: 30px;
  text-align: center;
}
.signup-form form {
  color: #999;
  border-radius: 6px;
  margin-bottom: 15px;
  background: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  padding: 30px;
}
.signup-form .form-group {
  margin-bottom: 40px;
}
.signup-form input[type="checkbox"] {
  margin-top: 7px;
}
.signup-form .btn {
  font-size: 16px;
  font-weight: bold;
  min-width: 140px;
  outline: none !important;
}
.signup-form .row div:first-child {
  padding-right: 10px;
}
.signup-form .row div:last-child {
  padding-left: 10px;
}
/* .signup-form a {
	color: #fff;
	text-decoration: underline;
} */
.signup-form a:hover {
  text-decoration: none;
}
.signup-form form a {
  color: #0077bf;
  text-decoration: none;
}
.signup-form form a:hover {
  text-decoration: underline;
}

.mar {
 /*  margin-top: -50%; */
  
}
.reg {
position: absolute;
  top:0;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
  display:flex;
  align-items: center;
}

.tex{

	margin-top: 10px;
}

/* @media only screen and (max-width: 1495px) {
  .Login {
    background-image: none;
    background-color:#ffffff;
  }

  .App{
  background-color:#021740;
}
  .form-control {
    height: 110px;
    box-shadow: none;
    color: #969fa4;
  }
 
}

@media only screen and (max-height:659px) {
  .Login {
    background-image: none;
    background-color:#ffffff;
  }
} */