.App {
  background-color: #021740;
  /*    height: 100vh; */
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: Arial "sans-serif";
}
::-webkit-scrollbar {
  width: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px wheat;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #021740;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #010d25;
}
