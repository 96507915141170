.contactt{
    width:53px;
    border-radius: 50% !important;
    
}
.contacts{
    margin-top:20px;
}

.it2{
    border-radius: 15px !important;
    width:350px !important;
}