.cadre-total {
  border-left: 4px solid #0d6efd ;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 15px;
  text-align: center;
}
.cadre-total * {
  padding: 14px;
  font-family: Arial;
}
.cadre-paye {
  border-left: 4px solid #157347;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 15px;
  text-align: center;

}

.cadre-paye  h4 {

  text-align: left;

}
.cadre-total *{
  padding: 12px;
  width: 220px;
  color:#0d6efd;
  font-family: Arial;
}

.cadre-total h4 {

  text-align: left;

}
.cadre-paye * {
  padding: 12px;
  width: 220px;
  color:#157347;
  font-family: Arial;
}

.cadre-nonpaye {
  border-left:4px solid  #bb2d3b;
 box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 15px;
  text-align: center;
}
.cadre-nonpaye * {
  padding: 12px;
  width: 220px;
  color:#bb2d3b;
  font-family: Arial;
}

.cadre-nonpaye  h4 {

  text-align: left;

}
.cdt{
 transition: all ease 0.4s;

}
.cdt:hover{
  transform: scale(1.3);
}
.dashboar-flex-res {
  font-size: 3rem;
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: space-around;
  
}
.input-search{
  height: 50px !important;
}

@media only screen and (max-width: 767px) {
  .dashboar-flex-res {
    display: block  !important;
    align-items: center;
     justify-content: space-between !important;
    
  }
}
